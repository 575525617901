import React from "react";
import styled from "styled-components";

import { vw } from "../utilities/helpers";
import SEO from "../utilities/seo";

import Block from "../components/block";
import Bit from "../components/bit";
import Glow from "../components/glow";
import Parallax from "../components/parallax";
import Hero from "../components/hero";
import Footer from "../components/footer";
import Text from "../components/text";
import Line from "../components/line";

import photo from "../assets/images/placeholders/cut-b.png";

const Wrap = styled.section`
	position: relative;
	margin-left: ${vw(3)};
	width: ${vw(17)};
	margin-top: ${vw(3.5)};

	h1 {
		padding-bottom: ${vw(1)};

		span:first-child {
			padding-bottom: ${vw(0.25)};
		}
	}

	p {
		width: 50%;
	}

	@media (max-width: 1023px) {
		width: 90%;
		margin-left: 5%;
	}

	@media (max-width: 767px) {
		width: 80%;
		margin: 0 auto;

		h1 {
			padding-bottom: 48px;
		}
	}
`;

export default () => {
	return (
		<>
			<main>
				<SEO title="Onboard" />

				<Block flex="true">
					<Glow royal top="-15%" left={vw(-4)} delay="0" />
					<Bit arrow top="25%" right="15%" />
					<Bit arrow top="45%" right="15%" />

					<Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
					<Bit ring top="55%" right="35%" />

					<Wrap>
						<Hero right="0" top="0">
							<img src={photo} alt="Intergenerational dialogue" />
						</Hero>
						<h1>
							<Text xlarge>Thank you!</Text>
						</h1>
						<p>
							Thank you for registering. We shall be intouch
							shortly. Keep around or engage us on the different
							social media platforms below.
						</p>
						<Line />
					</Wrap>
				</Block>

				<Parallax b />
			</main>
			<Footer />
		</>
	);
};
